<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Edit Sensor</p>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6" v-if="deviceSubTypeName != 'MonitorPiera'">
              <validated-text-field
                v-model="sensor.sensorName"
                label="Sensor Name"
                hint="Name for this Sensor"
                persistent-hint
                rules="required"
                outlined
              >
              </validated-text-field
            ></v-col>
            <v-col sm="5" v-else>
              <validated-text-field
                v-model="sensor.sensorName"
                label="Sensor Name"
                hint="Name for this Sensor"
                persistent-hint
                rules="required"
                outlined
                disabled="deviceSubTypeName === 'MonitorPiera'"
              >
              </validated-text-field></v-col
            ><v-col sm="1" v-if="deviceSubTypeName === 'MonitorPiera'"
              ><v-btn
                color="primary"
                class="mt-4"
                :to="`/monitors/site/${siteId}/piera/${monitorId}/edit`"
                Title="click to edit name of this monitor (sensor name for a Piera monitor cannot be directly edited)"
                ><v-icon>edit</v-icon></v-btn
              ></v-col
            >
            <v-col sm="6">
              <validated-select-field
                :items="monitors"
                item-value="id"
                item-text="deviceName"
                rules="required"
                v-model="sensor.monitorId"
                label="Monitor"
                hint="What monitor is this Sensor assigned to"
                persistent-hint
                disabled
              ></validated-select-field>
            </v-col> </v-row
          ><v-row>
            <v-col sm="6">
              <validated-select-field
                :items="sensorRegisters"
                item-value="registerId"
                item-text="registerName"
                rules="required"
                v-model="sensor.registerId"
                label="Register"
                hint="Assign Register to this Sensor"
                disabled
                persistent-hint
              ></validated-select-field
            ></v-col>
            <v-col sm="6">
              <validated-select-field
                :items="sensorUnitOfMeasures"
                item-value="id"
                item-text="unitOfMeasureName"
                rules="required"
                v-model="sensor.sensorUnitOfMeasureId"
                label="Sensor Unit of Measure"
                hint="What what unit of measure does this sensor use"
                persistent-hint
                disabled
              ></validated-select-field>
            </v-col>
          </v-row>
          <v-row
            ><v-col sm="6">
              <validated-select-field
                :items="sensorTypes"
                item-value="id"
                item-text="sensorTypeName"
                rules=""
                v-model="sensor.sensorTypeId"
                label="Sensor Type"
                hint="What sensor type does this sensor measure"
                persistent-hint
              ></validated-select-field>
            </v-col>
            <v-col sm="6">
              <validated-select-field
                :items="equipment"
                item-value="equipmentId"
                item-text="equipmentName"
                v-model="sensor.equipmentId"
                label="Equipment"
                hint="Assign Equipment to this Sensor"
                persistent-hint
                rules=""
                clearable
              ></validated-select-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                :disabled="!isValidSensor || invalid"
                >Save</v-btn
              >
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>

        <v-row justify="center">
          <v-dialog v-model="validateDialog" max-width="600">
            <v-card>
              <v-card-title class="headline">Validating Sensor</v-card-title>

              <v-card-text>
                <v-stepper v-model="vSteps" vertical>
                  <v-stepper-step
                    :complete="vSteps > 1 && isValidSensorName"
                    step="1"
                    color="green"
                  >
                    Validating Sensor Name
                    <small v-if="vSteps > 1 && isValidSensorName === true"
                      >sensor Name is valid</small
                    >
                    <v-row
                      style="width: 500px"
                      v-if="vSteps > 1 && isValidSensorName === false"
                    >
                      <v-col class="pt-0" cols="2" md="9" sm="9">
                        <small v-if="vSteps > 2 && isValidSensorName === false"
                          >Sensor names must be unique</small
                        >
                      </v-col>
                      <v-col cols="auto" md="3" sm="3">
                        <v-btn
                          class="mt-n7"
                          color="primary"
                          title="assign a Role"
                          @click="fixSensorErrors(0)"
                          >Fix</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-progress-linear
                      indeterminate
                      color="green"
                    ></v-progress-linear>
                  </v-stepper-content>
                </v-stepper>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="green darken-1"
                  text
                  @click="cancelValidationDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green darken-1"
                  :disabled="!isValidSensor || invalid"
                  text
                  @click="dismissValidationDialog"
                  >Continue</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form></validation-observer
    ></v-container
  >
</template>


<script>
import api from "./../_api";
//import goTo from "vuetify/es5/services/goto";
import { mixin as VueTimers } from "vue-timers";
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField,
    "validation-observer": ValidationObserver,
  },

  timers: {
    metaValidator: { time: 1000, autostart: false, repeat: true },
  },

  mixins: [VueTimers],

  data() {
    return {
      siteId: null,
      monitorId: null,
      registerId: null,
      sensorId: null,
      deviceSubTypeName: null,
      monitors: [],
      sensorRegisters: [],
      sensorUnitOfMeasures: [],
      sensorTypes: [],
      sensor: {},
      isValidSensor: true,

      valid: true,
      equipment: [],
      vSteps: null,
      validateDialog: false,
    };
  },

  created() {
    this.siteId = this.$route.params.siteId;
    this.monitorId = this.$route.params.monitorId;
    this.registerId = this.$route.params.registerId;
    this.sensorId = this.$route.params.sensorId;
    this.getLookups();
  },

  methods: {
    async getLookups() {
      const self = this;
      this.monitors = await api.getMonitorsForSite(this.siteId);
      console.log(this.monitors)
      console.log(this.monitorId)
      this.deviceSubTypeName = this.monitors.find(m => m.id == self.monitorId).deviceSubTypeName;
      console.log(this.deviceSubTypeName)
      this.sensorRegisters = await api.getSensorRegistersForMonitor(
        this.monitorId
      );
      this.sensorUnitOfMeasures = await api.getSensorUnitOfMeasureByRegisterId(
        this.registerId
      );

      this.sensor = await api.getSensor(
        this.siteId,
        this.monitorId,
        this.registerId,
        this.sensorId
      );
      this.sensorTypes = await api.getSensorTypes(
        this.sensor.sensorUnitOfMeasureId
      );
      this.equipment = await api.getEquipmentForSite(this.siteId);
    },

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }
      this.validateSensor();
    },

    // handleSetRegister(register) {
    //   console.log(register);
    // },

    // handleSetSensorUnitOfMeasure(unitOfMeasure) {
    //   console.log(unitOfMeasure);
    // },

    cancelValidationDialog() {
      this.validateDialog = false;
      this.$timer.stop("metaValidator");
      this.vSteps = 0;
    },

    async dismissValidationDialog() {
      try {
        this.cancelValidationDialog();
        this.sensor.siteId = this.siteId;
        await api.updateSensor(this.sensor);
        this.$router.push({ name: "SensorIndex" });
      } catch (error) {
        console.log(error);
      }
    },

    validateSensor() {
      this.validateDialog = true;
      this.$timer.start("metaValidator");
    },

    fixSensorErrors(item) {
      this.cancelValidationDialog();
      switch (item) {
        case 0:
          this.$nextTick().then(() => {
            this.focusName();
          });

          break;

        default:
          break;
      }
    },

    metaValidator() {
      this.vSteps++;

      if (this.vSteps == 1) {
        // compare proposed name to cached list of sensors for site
        this.isValidSensorName = true;
      }

      if (this.vSteps > 1) {
        this.$timer.stop("metaValidator");
      }

      this.isValidSensor = this.isValidSensorName;
    },

    focusName() {
      this.$refs.nameText.$refs.text.focus();
      this.isValidSensor = true;
    },

    handleCancelCrud() {
      this.$router.push({ name: "SensorIndex" });
    },
  },
};
</script>